let attemptsCount = 2
const wheelBtn = document.querySelector(".wheel__button")
const wheel = document.querySelector(".wheel");
const formWrapper = document.querySelector('.form-bg');
const pageWrapper = document.querySelector('.page-wrapper');
const mainWrapper = document.querySelector('.main');
const mainCounter = document.querySelector('.main__counter-digit');
const notificationBlock = document.querySelector('.notification');
const notifBtn = document.querySelector('.notification__btn');
let state = "initial"


function showNotification() {
    notificationBlock.classList.add("notification--active")
}

function showForm() {
    formWrapper.classList.add("showing");
    mainWrapper.classList.add("form-open")
    pageWrapper.classList.add("no-padding")
}

function setState(newState, className) {
    state = newState
    wheel.classList.add(className)
    wheelBtn.style.pointerEvents = 'none';

}

function drawCounter() {
    attemptsCount--
    mainCounter.textContent = attemptsCount
}

wheel.addEventListener('transitionend', () => {
    wheelBtn.style.pointerEvents = 'auto';
    if (state === "bonus1") {
        wheel.classList.add("bonus1-overlay");
        showNotification()
    } else if (state === "bonus2") {
        wheel.classList.add("bonus2-overlay");
        setTimeout(showForm, 2200)
    }

})

notifBtn.addEventListener('click', () => {
    showForm();
})

wheelBtn.addEventListener("click", () => {
    switch (state) {
        case "initial":
            setState("bonus1", "bonus1");
            drawCounter()
            break;
        case "bonus1":
            wheel.classList.remove("bonus1-overlay");
            setState("bonus2", "bonus2");
            drawCounter()
            wheelBtn.style.pointerEvents = 'none';
            break;
        default:
            break;
    }
})